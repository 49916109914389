<template>
  <div style="display: inline-block">
    <el-upload
      v-if="type == 'drag'"
      class="zz-sub-upload"
      :drag="type == 'drag'"
      action
      :limit="limit"
      :disabled="loadding"
      multiple
      :accept="accept"
      :http-request="() => {}"
      :beforeUpload="beforeAvatarUpload"
    >
      <i class="el-icon-upload" v-if="!loadding"></i>
      <div class="zz-sub-upload-text" v-if="!loadding">
        将文件拖到此处，或
        <em>点击上传</em>
      </div>
      <i class="el-icon-loading" v-if="loadding"></i>
      <div class="zz-sub-upload-text" style v-if="loadding">正在上传……</div>
    </el-upload>
    <el-upload
      v-if="type == 'button'"
      class="zz-sub-btn-upload"
      action
      :accept="accept"
      :http-request="() => {}"
      :disabled="loadding"
      :limit="limit"
      multiple
      :beforeUpload="beforeAvatarUpload"
    >
      <el-button type="primary" :loading="loadding" icon="search">
        <span v-if="$slots.default"><slot></slot></span>
      </el-button>
    </el-upload>
  </div>
</template>
<script>
import global from "@/common/global";
import {isEmpty, decodeUserInfo} from '@/common/tools'

export default {
  props: {
    url: String,
    limit: {
      type: [Number],
      default: 1
    },
    type: {
      type: String,
      default: "drag" //drag || button
    },
    xlsxLimit: {
      type: Boolean,
      default: false
    },
    accept: {},
    data: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      userInfo: decodeUserInfo(sessionStorage.getItem("userInfo")),
      loadding: false
    };
  },
  created() {
    // console.log(this.$slots.default, this, 'Ljalf')
  },
  methods: {
    beforeAvatarUpload(file) {
      var that = this;
      this.loadding = true;
      if (this.xlsxLimit) {
        var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extension = testmsg === "xls";
        const extension2 = testmsg === "xlsx";
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!extension && !extension2) {
          this.$message({
            message: "上传文件只能是 xls、xlsx格式!",
            type: "warning"
          });
          this.loadding = false;
          return false;
        }
        if (!isLt2M) {
          this.$message({
            message: "上传文件大小不能超过 10MB!",
            type: "warning"
          });
          this.loadding = false;
          return false;
        }
      }
      //console.log(file);
      let params = new FormData(); // 创建form对象
      params.append("file", file, file.name); // file对象是 beforeUpload参数
      for (var i in this.data) {
        let item = this.data[i]
        if (typeof item == 'object') {
          if (isEmpty(item.value)) {
            this.$message.warning(item.tip)
            this.loadding = false;
            return false;
          }
          params.append(i, item.value);
        } else {
          params.append(i, item);
        }
      }
      console.log(params, this.data, this.userInfo);
      // return false;
      return (
        this.$axios
          .post(that.url, params)
          .then(res => {
            if (res.data.code == "0") {
              this.$message.success(res.data.message);
              this.$nextTick(() => {
                this.$emit('uploaded', res.data)
              })
            } else {
              this.$message.error(res.data.message);
            }
            that.loadding = false;
          })
          .catch(error => {
            //console.log(error);
            that.loadding = false;
          }) && false
      );
    }
  }
};
</script>
<style lang='less'>
.zz-sub-upload {
  width: 100%;
  height: 100%;
  .el-upload--text,
  .el-upload-dragger {
    width: 100%;
    height: 100%;
  }
  .el-upload-dragger {
    border: 0 none;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
  .el-upload--text {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    .el-icon-upload {
      font-size: 60px;
      margin-bottom: 12px;
      margin-top: 0;
      color: #97a8be;
    }
    .el-icon-loading {
      font-size: 20px;
      margin-bottom: 12px;
      margin-top: 0;
      color: #97a8be;
    }
    .zz-sub-upload-text {
      color: #97a8be;
      font-size: 14px;
      em {
        color: #20a0ff;
      }
    }
  }
  .el-upload--text:hover,
  .el-upload--text:focus {
    border-color: #409eff;
  }
}

.zz-sub-btn-upload {
  display: inline-block;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  .el-upload--text {
    width: 100%;
    height: 100%;
    border: 0 none;
    border-radius: 0;
    margin: 0;
    padding: 0;
    vertical-align: top;
    .el-button {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }
  }
  .el-upload-list {
    display: none;
  }
}
</style>

